import styled from 'styled-components';
import {
  ReactTypes, DivTypes, FlexTypes, SizeTypes,
} from 'types';

export interface CenterType extends FlexTypes, ReactTypes, DivTypes, SizeTypes {}

const Center = styled.div<CenterType>`
    width:100%;
    height:${(p) => p.height || 'auto'};
    display:flex;
    padding:${(p) => p.padding || '0'};
    justify-content:${(p) => p.jc || 'center'};
    align-items:${(p) => p.ai || 'center'};
    background:${(p) => p.bgColor || p.theme.colors.white};
    flex-direction:${(p) => p.dir || 'row'};
    margin:${(p) => p.margin || 0};
    box-shadow:${(p) => p.boxShadow || 'none'};
    border-radius:${(p) => p.radius || '0'}
   
    @media (min-width: 992px) {
       width: 970px;
    }
    
    @media (min-width: 1200px) {
       width: 1170px;
    }
`;

export default Center;

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
  Sentry.init({
    dsn: 'https://53d1ebd952024670b11195eb9a94ba51@o460464.ingest.sentry.io/5460684',
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: process.env.REACT_APP_BUILD || 'local',
  });
  const user = JSON.parse(localStorage.getItem('user')) || {};
  if (user.user) {
    Sentry.setUser({
      email: user.user.email,
    });
  }
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

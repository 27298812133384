import React, { lazy, Suspense, useEffect } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import CookieConsent, { Cookies } from "react-cookie-consent";

import Base from 'styles/Base';
import Center, { CenterType } from 'styles/Center';

import Header from 'components/Header';
import Footer from 'components/Footer';
import AppLoading from 'components/Loading/AppLoading';
import { StickyContainer } from 'react-sticky';
import { Helmet } from 'react-helmet';
import Language from 'lang';
import { init } from 'jetstate';
import moment from 'moment';
import LoginPopup from 'pages/Auth/LoginPopup';
import AlertModal from 'components/AlertModal';
import ROUTE from 'vars/router';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import SlideMenu from 'components/SlideMenu';
import './assets/scss/main.scss';
import { useObserver } from 'mobx-react';
import useStores from './store/hooks/useStores';

const Evaluations = (lazy(() => import('pages/Evaluation')));
const WhoWeAre = (lazy(() => import('pages/WhoWeAre')));
const Contact = (lazy(() => import('pages/Contact')));
const Pdfs = (lazy(() => import('pages/Pdfs')));
const Articles = (lazy(() => import('pages/Articles')));
const UserSpace = (lazy(() => import('pages/UserSpace')));
const Article = (lazy(() => import('pages/Article')));
const Terms = (lazy(() => import('pages/Terms')));
const Reset = (lazy(() => import('pages/Auth/Reset')));
const Verify = (lazy(() => import('pages/Auth/Verify')));
const Signup = (lazy(() => import('pages/Auth/Signup')));
const OurTeams = (lazy(() => import('pages/OurTeams')));
const Home = (lazy(() => import('pages/Home')));
const Search = (lazy(() => import('pages/Search')));
const Session = (lazy(() => import('pages/Session')));
const Form = (lazy(() => import('pages/Form')));

const alertOptions = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.FADE,
  containerStyle: {
    zIndex: 9,
  },

};

export const themeConfigs = {
  colors: {
    ...Language.details.colors,

    white: '#ffffff',
    duck_egg_blue: '#d5edf6',
    light_grey_blue: '#83b6cb',
    white_two: '#dadada',
    white_three: '#f3f3f3',
    terracota: '#d07c40',
    white_four: '#efefef',
    tealish: '#26a6d1',
    dull_yellow: '#efc75e',
    dull_yellow_two: '#e8c15b',
    pale_red: '#e2574c',
    tomato: '#e42626',
    silver: '#d6d9db',
    gunmetal_two: '#525854',
    subscribe_title: '#003856',
    green: '#a7f552',
    red: '#f55252',
  },
};

const GlobalStyle = createGlobalStyle`
  :root {
    --primary-c: ${themeConfigs.colors.primary};
    --secondary-c: ${themeConfigs.colors.secondary};
    
  }


  * {
    padding:0;
    margin:0;
    box-sizing:border-box;
    font-family: Gravity Regular;
    /* transition:all 0.6s; */
  }
  body {
    font-family: Gravity Regular;
  }
  .svg-logo{
    opacity:0.9;
  }
  .svg-logo:hover{
    opacity:1;
  }
  .svg-fill-default{
    fill:${themeConfigs.colors.secondary};
  }

  .table-style-row.table-style-header {
    background:${(p:any) => p.theme.colors.default} !important;
  }

  .certification{
    background:${(p:any) => p.theme.colors.primary} !important;
  }


`;

const Navigation = () => {
  const { website_details } = useStores();

  useEffect(() => {
    website_details.checkVersion();
  }, []);

  return useObserver(() => {
    if (website_details.loading) return <AppLoading />;
    return (
      <div className="outer-container">
        <StickyContainer>
          <Helmet titleTemplate={`${Language.details.title}%s`}>
            <title> </title>
            {/* <meta name="description" content="L'AFML vous propose ses séminaires partout en France, ainsi que ses formations e-learning" /> */}
          </Helmet>

          <Router>
            <SlideMenu />
            <div id="main">
              <Header />
              <Suspense fallback={<AppLoading />}>
                <Switch>
                  <Route path={ROUTE.SESSION}>
                    <Session />
                  </Route>
                  <Route path={ROUTE.SESSION_WITH_NAME}>
                    <Session />
                  </Route>
                  <Route path={ROUTE.AUTH_SIGN_UP}>
                    <Signup />
                  </Route>
                  <Route path={ROUTE.AUTH_RESET}>
                    <Reset />
                  </Route>
                  <Route path={ROUTE.SEARCH}>
                    <Search />
                  </Route>
                  <Route path={ROUTE.OUR_TEAM}>
                    <OurTeams />
                  </Route>
                  <Route path={ROUTE.TERMS}>
                    <Terms />
                  </Route>
                  <Route path={ROUTE.ARTICLES}>
                    <Articles />
                  </Route>
                  <Route path={ROUTE.PDFS}>
                    <Pdfs />
                  </Route>
                  <Route path={ROUTE.ARTICLE}>
                    <Article />
                  </Route>
                  <Route path={ROUTE.CONTACT}>
                    <Contact />
                  </Route>
                  <Route path={ROUTE.WHO_WE_ARE}>
                    <WhoWeAre />
                  </Route>
                  <Route path={ROUTE.USER_SPACE}>
                    <UserSpace />
                  </Route>
                  <Route path={ROUTE.VERIFY_EMAIL}>
                    <Verify />
                  </Route>
                  <Route path={ROUTE.EVALUATIONS}>
                    <Evaluations />
                  </Route>
                  <Route path={ROUTE.FORM}>
                    <Form />
                  </Route>
                  <Route path="/">
                    <Home />
                    {/* <SubscribeNewsletter /> */}
                  </Route>
                </Switch>
              </Suspense>

              <Footer />
              <LoginPopup />
              <AlertModal />
              <CookieConsent
                location="bottom"
                buttonText="OK tout accepter"
                cookieName="dopamineCookie"
                style={{ background: "#2B373B", fontSize: "15px" }}
                buttonStyle={{ color: "#4e503b", fontSize: "14px" }}
                expires={150}
              >
                Nous utilisons des cookies pour vous garantir la meilleure expérience sur notre site 
                <b> Si vous continuez à utiliser ce dernier, nous considérerons que vous acceptez l'utilisation des cookies. Pour en savoir plus, consultez notre</b> <Link to={ROUTE.TERMS}>Nos conditions générales de prestation de service.</Link>
              </CookieConsent>
            </div>
          </Router>
        </StickyContainer>
      </div>
    );
  });
};

const App: React.FC = () => {
  init({
    name: 'cities',
    defaultValue: null,
  });
  init({
    name: 'regions',
    defaultValue: null,
  });
  init({
    name: 'search_text',
    defaultValue: '',
  });
  init({
    name: 'selected_regions',
    defaultValue: [],
  });
  init({
    name: 'selected_cities',
    defaultValue: [],
  });
  init({
    name: 'selected_type',
    defaultValue: [],
  });
  init({
    name: 'selectedFunder',
    defaultValue: [],
  });
  init({
    name: 'selected_profession',
    defaultValue: [],
  });
  init({
    name: 'selected_date_range',
    defaultValue: {
      start: moment(),
      end: moment().add(1, 'year'),
    },
  });
  init({
    name: 'user',
    defaultValue: null, // {"token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjozLCJ1c2VybmFtZSI6ImFAYWJjLmNvbSIsImV4cCI6MTYwNjI1MTU5MSwiZW1haWwiOiJhQGFiYy5jb20ifQ.VF8dkAxn8NDqPfyGDX60vvsoISyC6gIB3BGNwn64HEE","user":{"id":3,"last_login":null,"date_joined":"2019-11-25T20:59:49.799147Z","title":"Mr","firstName":"David","lastName":"Donella","centralId":null,"gender":"M","birthDate":"2019-11-06","mobileNumber":"+123456","email":"a@abc.com","organiser":false,"presentator":false,"creator":false,"isMiddleManager":false,"isMember":true,"verified":null,"tempID":null,"username":"a@abc.com","userID":null,"createdAt":"2019-11-25","updatedAt":"2019-11-25","userType":null}} //null
  });
  init({
    name: 'session',
    defaultValue: null,
  });
  return (
    <ThemeProvider theme={themeConfigs}>
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <GlobalStyle />
        <Navigation />
      </AlertProvider>
    </ThemeProvider>
  );
};

export default App;

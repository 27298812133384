import AUTH_KEYS from 'vars/auth';
import { state } from 'jetstate';
import { emit } from 'jetemit';
import * as Sentry from '@sentry/browser';

export function setUser(response:any, toggle = true) {
  state.user = response;
  window.localStorage.setItem(AUTH_KEYS.USER_LOCALSTROAGE_NAME, JSON.stringify(response));
  if (toggle) emit(AUTH_KEYS.TOGGLE_AUTH_MODAL);
  emit(AUTH_KEYS.USER_UPDATE);

  Sentry.configureScope((scope) => {
    scope.setUser({
      email: response.user.email,
    });
  });
}

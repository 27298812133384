import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

const LOCALSTORAGE_VISITOR_ID_KEY = 'dopamine_visitor_id';

function createUUID():string {
  return uuidv4();
}

function setLocal(val) : void {
  window.localStorage.setItem(LOCALSTORAGE_VISITOR_ID_KEY, JSON.stringify({
    expire: moment().add('hour', 1).toString(),
    uuid: val,
  }));
}

function VisitorId(): string {
  const id:string = window.localStorage.getItem(LOCALSTORAGE_VISITOR_ID_KEY);
  if (id) {
    const idObject = JSON.parse(id);
    if (moment().isSameOrBefore(idObject.expire)) {
      return idObject.uuid;
    }
  }
  const uu = createUUID();
  setLocal(uu);
  return uu;
}

export default VisitorId;

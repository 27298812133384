import axios from 'axios';
import { state } from 'jetstate';
import yn from 'yn';
import Language, { BASE_URL } from '../lang';
import VisitorId from './visitorId';
import Logout from '../helper/logout';

export const ERROR_CODES = {
  INVALID: 'invalid',
  MIN_LENGTH: 'min_length',
  EMPTY: 'empty',
  UNIQUE: 'unique',
};

function connectionError(msgText = Language.errors.fetch) {
  alert(msgText); // eslint-disable-line no-alert
}

// Fetch Data from api
// eslint-disable-next-line consistent-return
const ApiCall = async (action = '', options = {}) => {
  const {
    log, method, noToken, acceptableStatuses = [200], forceToken, hardLog,
    data, justData, justStatus, errorStatuses = [],
  } = options;

  // set token
  /*
   * define token
   * default empty
   *
   * if debug is true (.env) sets to test_token (.env)
   *
   * if debug is false and state.token is defined sets to state.token
   *
   * if forceToken defines token sets to forceToken
   * ApiCall('{somePage}',{forceToken:'MY LOVELY FORCE TOKEN'})
  */
  let token = '';
  if (!noToken) {
    if (yn(process.env.REACT_APP_debug)) {
      token = process.env.REACT_APP_test_token;
    } else {
      if (state.user) token = state.user.token;
      token = forceToken || token;
    }
  }

  /*
   * set token schema
   * default JWT
   * ApiCall('{somePage}',{acceptableStatuses: [200]})
  */
  const tokenSchema = 'Bearer';

  const URL = (action.startsWith('http') || action.startsWith(BASE_URL)) ? action : BASE_URL + action;

  /*
   * acceptable response
   * ApiCall('{somePage}',{acceptableStatuses: [200]})
   * ApiCall('{somePage}',{acceptableStatuses: [200,400,404]})
  */

  const formData = new FormData();

  /*
  * set body
  * ApiCall('{somePage}',{data: {name:'mohammad',age:'19'} })
  */
  if (options.data) {
    Object.keys(options.data).forEach((e) => {
      formData.append(e, options.data[e]);
    });
  }

  /*
  * upload file(s)
  * ApiCall('{somePage}',{files: {avatar:'absolute uri path from fetch_blob uri'} })
  */
  if (options.files) {
    Object.keys(options.files).forEach((e) => {
      if (options.files[e]) formData.append(e, options.files[e]);
    });
  }

  // eslint-disable-next-line no-console
  if (log || hardLog) console.log('Request ', method, URL, ' => ', justData ? (data || null) : formData);

  const headers = {
    'X-RESOLUTION': [
      `${window.screen.width * window.devicePixelRatio}`,
      `${window.screen.height * window.devicePixelRatio}`,
    ].join('x'),
    'X-VISITOR-ID': VisitorId(),
  };

  if (token) headers.Authorization = `${tokenSchema} ${token}`;

  let response = {};
  try {
    response = await axios({
      method,
      url: URL,
      headers,
      data: justData ? (data || null) : formData,
    });
  } catch (e) {
    if (errorStatuses.length > 0 && e.response && e.response.status && errorStatuses.includes(e.response.status)) {
      return {
        hasError: true,
        status: e.response.status,
        data: e.response.data,
      };
    }

    /*
     * check for acceptable status in out of 200 range
    */

    // log and resolve false
    // eslint-disable-next-line no-console
    if (log || hardLog) console.warn(e, e.response);

    const errorResponseObject = {
      500: () => connectionError(Language.error_500),
      502: () => connectionError(Language.error_502),
      401: () => {
        Logout();
        connectionError(Language.messages.signout);
      },
      400: connectionError,
      403: connectionError,
    };

    if (e.response) {
      const hasRequestFailed = e.response.status >= 400;
      if (hasRequestFailed && acceptableStatuses.includes(e.response.status)) {
        return e.response.status;
      }
      return errorResponseObject[e.response.status]();
    }
    return false;
  }

  if (acceptableStatuses.includes(response.status)) {
    /*
    * resolve true if there is no need to return response json
    * ApiCall('{somePage}',{ acceptableStatuses: [201] , justStatus:true })
    */
    if (justStatus) return true;

    /*
    * check json data of response and return it
    * const r = await ApiCall('{somePage}');
    * console.log(r.name)
    */
    if (response.data) {
      if (response.data.hasError) {
        if (response.data.onError) {
          options.onError(response.message);
        } else if (response.data.message) {
          connectionError(response.data.message);
        }
        return false;
      }
      return response.data;
    }
    /*
                        * if response status is in 200 range but it's not our status
                        */
    connectionError();
    return false;
  }
};

export default ApiCall;

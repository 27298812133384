import Language from 'lang';

export const login: any = {
  website: Language.details.website_type_key,
  email: '',
  password: '',
  code: '',
};

export const initialLogin: LoginStateType = {
  ...login,
};

export interface LoginStateType {
  [key: string]: any;
}

export interface IErrorLogin {
  email: boolean;
  password: boolean;
  code:boolean;
}

export const initialErrorsLogin: IErrorLogin = {
  email: true,
  password: true,
  code: false,
};

/// Forgot
export const forgot: any = {
  website: Language.details.website_type_key,
  email: '',
  password: '',
  code: '',
};

export const initialForgot: LoginStateType = {
  ...forgot,
};

export interface IErrorForgot {
  email: boolean;
  password: boolean;
  code:boolean;
}

export const initialErrorsForgot: IErrorForgot = {
  email: true,
  password: false,
  code: false,
};

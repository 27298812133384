import styled from 'styled-components';
import {
  FlexTypes, DivTypes, ReactTypes, SizeTypes,
} from 'types';

interface BaseType extends FlexTypes, ReactTypes, DivTypes, SizeTypes {}

const Base = styled.div<BaseType>`
    width:100%;
    height:${(p) => p.height || 'auto'};
    display:flex;
    justify-content:${(p) => p.jc || 'center'};
    align-items:${(p) => p.ai || 'center'};
    flex-direction:${(p) => p.dir || 'column'};
    background:${(p) => p.bgColor || (p.colorFromTheme && p.theme.colors[p.colorFromTheme]) || p.theme.colors.white};
    padding:${(p) => p.padding || 0};
    margin:${(p) => p.margin || 0};
    z-index:${(p) => p.zIndex || 1};
    box-shadow:${(p) => p.boxShadow || 'none'};
`;

export default Base;

import styled from 'styled-components';
import {
  ReactTypes, DivTypes, FlexTypes, TextTypes, SizeTypes,
} from 'types';

type TextType = FlexTypes & ReactTypes & DivTypes & TextTypes & SizeTypes;

const Text = styled.p<TextType>`
    color:${(p) => p.color || (p.colorFromTheme && p.theme.colors[p.colorFromTheme]) || p.theme.colors.text};
    font-size:${(p) => p.size || '12pt'};
    font-weight:${(p) => p.weight || 'normal'};
    line-height:${(p) => p.lineHeight || 'normal'};
    padding:${(p) => p.padding || '0'};
    background:${(p) => p.bgColor || 'transparent'};
    text-align:${(p) => p.align || ''};
    text-shadow:${(p) => p.textShadow || 'none'};
    margin:${(p) => p.margin || 0};
    letter-spacing:${(p) => p.letterSpacing || 'normal'};
    cursor:${(p) => p.cursor || 'auto'};
    text-decoration:${(p) => p.decoration || 'none'};
    width:${(p) => p.width || 'auto'};
    white-space:${(p) => p.whiteSpace || 'initial'}
    transition:all .6s;
    &:hover {
    	background:${(p) => p.hoverBgColor};
    }
`;

export default Text;

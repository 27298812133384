interface UserNameData {
  title?: string,
  firstName?: string,
  lastName?: string,
}

function UserName(data: UserNameData): string {
  const name = [];
  if (data.title) name.push(data.title);
  if (data.firstName) name.push(data.firstName);
  if (data.lastName) name.push(data.lastName);
  return name.join(' ');
}

export default UserName;

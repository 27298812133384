import React, { useContext } from 'react';
import { RootInstance } from 'store/models/types';
import storesContext from '../models';

function useStores() : RootInstance {
  const store = useContext(storesContext);
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }
  return useContext(storesContext);
}

export default useStores;

import React from 'react';
import Base from 'styles/Base';
import LoadingSvg from 'assets/svg/loading';
import Center from 'styles/Center';

export default function AppLoading() {
  return (
    <Base bgColor="white" jc="center" ai="center">
      <div style={{ height: 50 }} />
      <Center jc="center" ai="center" height="300px">
        <LoadingSvg />
      </Center>
      <div style={{ height: 50 }} />
    </Base>
  );
}

import AFMLGrand from '../site/AFMLGrand.json';
import AFML from '../site/AFML.json';
import Tamari06 from '../site/Tamari06.json';
import VFL from '../site/VFL.json';
import Entreprises from '../site/Entreprises.json';
import Errors from './errors.json';
import Commons from './common.json';

const hostname = document.location.hostname.replace('www.', '');
export const BASE_URL = process.env.REACT_APP_BASE_URL;

const SitesData = {
  'afml-gs.org': {
    lang: AFMLGrand,
  },
  'devafmlgs.smart-gamma.com': {
    lang: AFMLGrand,
  },
  'lafml.org': {
    lang: AFML,
  },
  'devlafml.smart-gamma.com': {
    lang: AFML,
  },
  'tamari06.org': {
    lang: Tamari06,
  },
  'devtamari.smart-gamma.com': {
    lang: Tamari06,
  },
  'vfl-formation.com': {
    lang: VFL,
  },
  'dev.vfl-formation.tk': {
    lang: VFL,
  },
  'dopamine-entreprises.com': {
    lang: Entreprises,
  },
  'vfl-formation.smart-gamma.com': {
    lang: Entreprises,
  },
};

function Lang() : any {
  const site = SitesData[hostname] ? SitesData[hostname].lang : AFML;
  return {
    ...site,
    ...Errors,
    ...Commons,
  };
}

export default Lang();

import React from 'react';
import { types, applySnapshot, getParent } from 'mobx-state-tree';
import { QUESTION_TYPE, IQuestionModel, IAnswerModel } from './types';

const AnswerModel = types.model({
  id: types.integer,
  order: types.integer,
  text: types.string,
  selected: types.optional(types.boolean, false),
}).actions((self) => {
  function setSelected(val:boolean) {
    self.selected = val;

    // dismiss error
    const parent:any = getParent(self, 2);
    if (parent && parent.checkError) parent.checkError();
  }

  return {
    setSelected,
  };
});

const QuestionModel = types.model({
  name: types.string,
  question_type: types.union(
    types.literal(QUESTION_TYPE.TEXT),
    types.literal(QUESTION_TYPE.RADIO),
    types.literal(QUESTION_TYPE.CHECKNOX),
  ),
  answer_text: types.optional(types.string, ''), // Just for QUESTION_TYPE.TEXT
  error: types.optional(types.boolean, false),
  answers: types.optional(types.array(AnswerModel), []),
  order: types.integer,
  is_required: types.boolean,
}).actions((self) => {
  function setSelected(id:number) {
    self.answers.forEach((e) => {
      if (e.id !== id && e.selected) e.setSelected(false);
      if (e.id === id) e.setSelected(true);
    });

    if (self.error) self.error = false;
  }

  function getSingleAnswer(id:number) {
    return self.answers.filter((ans:IAnswerModel) => ans.id === id)[0];
  }

  function setTextAnswer(val:string) {
    self.answer_text = val;
    self.error = !val;
  }

  function setError(val:boolean) {
    self.error = val;
  }

  function checkError() {
    self.error = !self.answers.some((e) => e.selected);
  }

  return {
    setSelected,
    getSingleAnswer,
    setTextAnswer,
    setError,
    checkError,
  };
}).views((self) => ({
  get getAnswers() : IAnswerModel[] {
    return self.answers;
  },
}));

const FormModel = types.model({
  public_name: types.optional(types.string, ''),
  public_description: types.optional(types.string, ''),
  questions: types.optional(types.array(QuestionModel), []),
  isLoading: types.optional(types.boolean, false),
}).actions((self) => {
  function setDetails(name:string, des:string, questions:IQuestionModel[]) {
    self.public_name = name;
    self.public_description = des;
    applySnapshot(self.questions, questions);
  }

  function setQuestions(data:IQuestionModel[]) {
    applySnapshot(self.questions, data);
  }

  function setLoading(val:boolean) {
    self.isLoading = val;
  }

  function getSingleQuestion(data:IQuestionModel) {
    return self.questions.filter((e) => e.name === data.name && e.order === data.order)[0];
  }

  return {
    setDetails,
    setQuestions,
    getSingleQuestion,
    setLoading,
  };
}).views((self) => ({
  get getQuestions() {
    return self.questions.sort((a, b) => a.order - b.order);
  },
}));

export default FormModel;
export {
  AnswerModel,
  QuestionModel,
};

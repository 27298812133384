import { useState } from 'react';
import ApiCall from 'utils/fetch';
import { ValidationError } from 'helper/fetchValidationError';
import { setUser } from 'helper/user';
import Language from 'lang';
import AUTH_KEYS from 'vars/auth';
import { useAlert } from 'react-alert';
import { emit } from 'jetemit';
import { forgot, IErrorForgot, initialErrorsForgot } from '../model';

export default () => {
  const [forgotLoading, setLoading] = useState(false);
  const [forgotGetVerifyCode, setGetVerifyCode] = useState(false);
  const [forgotErrors, setErrors] = useState<IErrorForgot>(initialErrorsForgot);
  const [forgotFetchErrors, setFetchErrors] = useState<object[]|any>([]);
  const alert = useAlert();
  function validate(name:any, text:any, justReturn = false) {
    const validate_errors:any = {};
    if (justReturn) return validate_errors;
    setErrors({ ...forgotErrors, [name]: !!(typeof text === 'string' && text === ''), ...validate_errors });
  }

  const onChange = (name: any, options:any = {}) => (text: any) => {
    const value = text.nativeEvent ? text.nativeEvent.text.toLowerCase() : text;
    // validate
    if (options.validate) validate(options.sub ? options.sub : name, value, false);

    forgot[name] = value;
  };

  async function submitForgot() {
    if (forgotLoading) return;
    if (Object.values(forgotErrors).every((e) => !e)) {
      setFetchErrors([]);
      setLoading(true);
      if (forgotGetVerifyCode) {
        const response = await ApiCall('/user/createnewuser/verify_reset_password/', {
          method: 'post', log: true, data: forgot, justData: true, noError: true, acceptableStatuses: [201], errorStatuses: [404, 401, 400],
        });
        if (response.token) {
          setGetVerifyCode(false);
          setUser(response);
        }
      } else {
        const response = await ApiCall('/user/createnewuser/reset_password/', {
          method: 'post', log: true, data: forgot, justData: true, noError: true, acceptableStatuses: [201], errorStatuses: [404, 401, 400],
        });
        console.log('res => ', response);
        if (response && response.hasError) {
          if (response.data.error || response.data.message) {
            setFetchErrors([{ name: '', error: response.data.error || response.data.message }]);
          } else {
            setFetchErrors(ValidationError(response.data));
          }
        } else if (response && !response.hasError) {
          alert.success(Language.auth.reset_link);
          emit(AUTH_KEYS.TOGGLE_AUTH_MODAL);
        }
      }

      setLoading(false);
    }
  }

  function resetForgot() {
    setErrors(initialErrorsForgot);
    setGetVerifyCode(false);
    setFetchErrors([]);
    setLoading(false);
  }

  return {
    onChange,
    submitForgot,
    forgotLoading,
    forgotErrors,
    forgotFetchErrors,
    forgotGetVerifyCode,
    resetForgot,
  };
};

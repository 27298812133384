const ROUTE = {
  AUTH_SIGN_UP: '/signup',
  AUTH_RESET: '/reset/:code/:email',
  SEARCH: '/search',
  OUR_TEAM: '/team',
  TERMS: '/terms',
  SESSION: '/session/:id',
  SESSION_WITH_NAME: '/session/:id/:name',
  SESSION_USE: '/session/',
  CONTACT: '/contact',
  PDFS: '/pdfs',
  ARTICLES: '/articles',
  ARTICLE: '/article/:id',
  ARTICLE_WITH_NAME: '/article/:id/:name',
  ARTICLE_USE: '/article/',
  VERIFY_EMAIL: '/verify',
  USER_SPACE: '/me',
  WHO_WE_ARE: '/who-we-are',
  SIDE_MENU: 'sideMenu',
  EVALUATIONS: '/evaluations',
  FORM: '/form/:form_id',
  FORM_USE: 'form',
};
export default ROUTE;

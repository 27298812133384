import React from 'react';
import Color from 'color';
import { themeConfigs } from 'App';
// style={props.style}
// {Color(themeConfigs.colors.secondary).lighten(0.45)}
export default function LoadingSvg(props:any) {
  return (
    <svg className="lds-blocks" width={props.width || '200px'} height={props.height || '200px'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style={props.style}>
      <rect x="16" y="16" width="20" height="20" fill={Color(themeConfigs.colors.primary).lighten(0.95)}>
        <animate attributeName="fill" values={`${themeConfigs.colors.primary};${Color(themeConfigs.colors.primary).lighten(0.95)};${Color(themeConfigs.colors.primary).lighten(0.95)}`} keyTimes="0;0.125;1" dur="2.6s" repeatCount="indefinite" begin="0s" calcMode="discrete" />
      </rect>
      <rect x="40" y="16" width="20" height="20" fill={Color(themeConfigs.colors.primary).lighten(0.95)}>
        <animate attributeName="fill" values={`${themeConfigs.colors.primary};${Color(themeConfigs.colors.primary).lighten(0.95)};${Color(themeConfigs.colors.primary).lighten(0.95)}`} keyTimes="0;0.125;1" dur="2.6s" repeatCount="indefinite" begin="0.325s" calcMode="discrete" />
      </rect>
      <rect x="64" y="16" width="20" height="20" fill={Color(themeConfigs.colors.primary).lighten(0.95)}>
        <animate attributeName="fill" values={`${themeConfigs.colors.primary};${Color(themeConfigs.colors.primary).lighten(0.95)};${Color(themeConfigs.colors.primary).lighten(0.95)}`} keyTimes="0;0.125;1" dur="2.6s" repeatCount="indefinite" begin="0.955s" calcMode="discrete" />
      </rect>
      <rect x="16" y="40" width="20" height="20" fill={Color(themeConfigs.colors.primary).lighten(0.95)}>
        <animate attributeName="fill" values={`${themeConfigs.colors.primary};${Color(themeConfigs.colors.primary).lighten(0.95)};${Color(themeConfigs.colors.primary).lighten(0.95)}`} keyTimes="0;0.125;1" dur="2.6s" repeatCount="indefinite" begin="2.275s" calcMode="discrete" />
      </rect>
      <rect x="64" y="40" width="20" height="20" fill={Color(themeConfigs.colors.primary).lighten(0.95)}>
        <animate attributeName="fill" values={`${themeConfigs.colors.primary};${Color(themeConfigs.colors.primary).lighten(0.95)};${Color(themeConfigs.colors.primary).lighten(0.95)}`} keyTimes="0;0.125;1" dur="2.6s" repeatCount="indefinite" begin="0.9750000000000001s" calcMode="discrete" />
      </rect>
      <rect x="16" y="64" width="20" height="20" fill={Color(themeConfigs.colors.primary).lighten(0.95)}>
        <animate attributeName="fill" values={`${themeConfigs.colors.primary};${Color(themeConfigs.colors.primary).lighten(0.95)};${Color(themeConfigs.colors.primary).lighten(0.95)}`} keyTimes="0;0.125;1" dur="2.6s" repeatCount="indefinite" begin="1.9500000000000002s" calcMode="discrete" />
      </rect>
      <rect x="40" y="64" width="20" height="20" fill={Color(themeConfigs.colors.primary).lighten(0.95)}>
        <animate attributeName="fill" values={`${themeConfigs.colors.primary};${Color(themeConfigs.colors.primary).lighten(0.95)};${Color(themeConfigs.colors.primary).lighten(0.95)}`} keyTimes="0;0.125;1" dur="2.6s" repeatCount="indefinite" begin="1.625s" calcMode="discrete" />
      </rect>
      <rect x="64" y="64" width="20" height="20" fill={Color(themeConfigs.colors.primary).lighten(0.95)}>
        <animate attributeName="fill" values={`${themeConfigs.colors.primary};${Color(themeConfigs.colors.primary).lighten(0.95)};${Color(themeConfigs.colors.primary).lighten(0.95)}`} keyTimes="0;0.125;1" dur="2.6s" repeatCount="indefinite" begin="1.3s" calcMode="discrete" />
      </rect>
    </svg>
  );
}

import React from 'react';
import Base from 'styles/Base';
import Center from 'styles/Center';
import './style.css';
import Text from 'styles/Text';
import Language from 'lang';
import Row from 'styles/Row';
import styled from 'styled-components';
import DirectionLogo from 'assets/svg/directions.svg';
import FacebookIcon from 'assets/svg/facebook';
import LinkedinIcon from 'assets/svg/linkedin';
import WhatsappIcon from 'assets/svg/whatsapp';
import TwitterIcon from 'assets/svg/twitter';
import EmailLogo from 'assets/svg/email.svg';
import PhoneLogo from 'assets/svg/phone.svg';
import { Link } from 'react-router-dom';
import Top from './top';
import useStores from '../../store/hooks/useStores';

export default function Footer() {
  const { website_details } = useStores();
  const iconStyle = {
    style: {
      margin: '10px',
    },
    pathStyle: {
      fill: 'white',
    },
    width: '25',
    height: '25',
  };

  const socials = {
    facebook: website_details.details.social_facebook || '',
    twitter: website_details.details.social_twitter || '',
    linkedIn: website_details.details.social_linkedin || '',
    whatsapp: website_details.details.whats_app_uri || '',
  };
  return (
    <>
      <Base colorFromTheme="primary" className="footer">
        <Top />

        <Center bgColor="transparent" jc="center" dir="column">
          <div style={{ height: 50 }} />

          <div className="row">

            <div className="col-12 col-md-4 mb-5 mb-md-0">
              <LogoImg src={Language.details.footer_logo} />
              <Text color="white" size="12pt" lineHeight="32px">{website_details.details.footer_text || ''}</Text>
            </div>

            <div className="col-12 col-md-4 mb-5 mb-md-0">
              <Text
                color="white"
                size="25pt"
                lineHeight="32px"
                margin="0 0 25px 0"
                className="d-flex justify-content-center justify-content-md-start"
                weight="bold"
              >
                {Language.sections.footer.find_us}
              </Text>

              <div className="social-links">
                {socials.facebook
                  ? <a href={socials.facebook} target="_blank"><FacebookIcon {...iconStyle} /></a> : null}

                {socials.linkedIn
                  ? <a href={`https://www.linkedin.com/company/${Language.socials.linked_in}`} target="_blank"><LinkedinIcon {...iconStyle} /></a> : null}

                {socials.twitter
                  ? <a href={`${socials.twitter}`} target="_blank"><TwitterIcon {...iconStyle} /></a> : null}

                {socials.whatsapp
                  ? <a href={`${socials.whatsapp}`} target="_blank"><WhatsappIcon {...iconStyle} /></a> : null}
              </div>

              <Row className="d-flex justify-content-center justify-content-md-start" margin="10px 7px">
                <Icon src={DirectionLogo} />
                <Text color="white" size="12pt" margin="0 10px">{website_details.details.contact_us_address || ''}</Text>
              </Row>

              <Row className="d-flex justify-content-center justify-content-md-start" margin="10px 7px">
                <Icon src={EmailLogo} />
                <Text color="white" size="12pt" margin="0 10px">{website_details.details.contact_us_email || ''}</Text>
              </Row>

              <Row className="d-flex justify-content-center justify-content-md-start" margin="10px 7px">
                <Icon src={PhoneLogo} />
                <Text color="white" size="12pt" margin="0 10px">{website_details.details.contact_us_phone || ''}</Text>
              </Row>

            </div>

            <div className="col-12 col-md-4 mb-5 mb-md-0">
              <Text
                color="white"
                size="25pt"
                lineHeight="32px"
                margin="0 0 15px 0"
                className="d-flex justify-content-center justify-content-md-start"
                weight="bold"
              >
                {Language.details.title}
              </Text>
              {Language.sections.footer.name_sub_list.map((item, index) => (
                <Link to={item.link} style={{ textDecoration: 'none' }} key={String(index)}>
                  <Text color="white" size="12pt" margin="10px" cursor="pointer">{item.name}</Text>
                </Link>
              ))}
            </div>

          </div>

        </Center>
        <div style={{ height: 50 }} />
      </Base>
      <Base colorFromTheme="secondary" className="copyright">
        <Center bgColor="transparent" jc="center" ai="center">
          <Text color="white" size="12pt" margin="10px">{Language.sections.footer.copy}</Text>
        </Center>
      </Base>
    </>
  );
}

const Box = styled.div`
    display:flex;
    flex:1;
    justify-content:center;
    align-items:flex-start;
    flex-direction:column;
    height:100%;
    padding:0 20px;
`;

const LogoImg = styled.img`
    width:80%;
    margin-bottom:10px;
    margin-left:-8px;
`;
const Icon = styled.img`
    width:30px;
    height:30px;
`;

// const CustomBase = styled(Base)`
//     margin-top:300px;
//     position: relative;
//     &:before{
//         background: url(${FooterTop}) no-repeat;
//         content: "";
//         position: absolute;
//         bottom: 100%;
//         width: 100%;
//         height: 193px;
//         margin-bottom: -1px;
//     }
// `;

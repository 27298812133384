import React from 'react';
import { IconTypes } from 'types';

export default function FacebookIcon(props:IconTypes) {
  return (
    <svg style={props.style} xmlns="http://www.w3.org/2000/svg" width={props.width || '40'} height={props.height || '40'} viewBox="0 0 34.194 34.194">
      <path id="facebook" d="M29.185 0H5.009A5.015 5.015 0 0 0 0 5.009v24.176a5.015 5.015 0 0 0 5.009 5.009h10.084V22.106h-4.007V16.1h4.007v-4.079a6.017 6.017 0 0 1 6.007-6.01h6.077v6.011H21.1V16.1h6.077l-1 6.011H21.1v12.083h8.081a5.015 5.015 0 0 0 5.009-5.009V5.009A5.015 5.015 0 0 0 29.185 0zm0 0" className="svg-logo svg-fill-default" style={props.pathStyle} />
    </svg>
  );
}

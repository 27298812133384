import React from 'react';
import { IconTypes } from 'types';

export default function TwitterIcon(props:IconTypes) {
  return (
    <svg style={props.style} xmlns="http://www.w3.org/2000/svg" width={props.width || '40'} height={props.height || '40'} viewBox="0 0 42.085 34.194">
      <g id="twitter" className="svg-logo svg-fill-default">
        <g id="Group_102" data-name="Group 102">
          <path id="Path_69" d="M42.085 52.048a17.988 17.988 0 0 1-4.971 1.362 8.579 8.579 0 0 0 3.8-4.769 17.242 17.242 0 0 1-5.471 2.088 8.627 8.627 0 0 0-14.924 5.9 8.884 8.884 0 0 0 .2 1.967A24.421 24.421 0 0 1 2.93 49.573 8.63 8.63 0 0 0 5.581 61.1a8.521 8.521 0 0 1-3.9-1.063v.095A8.667 8.667 0 0 0 8.6 68.614a8.611 8.611 0 0 1-2.262.284A7.628 7.628 0 0 1 4.7 68.75a8.71 8.71 0 0 0 8.062 6.01 17.336 17.336 0 0 1-10.7 3.68A16.161 16.161 0 0 1 0 78.322a24.289 24.289 0 0 0 13.236 3.872c15.876 0 24.556-13.151 24.556-24.551 0-.381-.013-.75-.032-1.115a17.212 17.212 0 0 0 4.325-4.48z" data-name="Path 69" transform="translate(0 -48)" style={props.pathStyle} />
        </g>
      </g>
    </svg>
  );
}
